import List from '@mui/material/List';
import useFRead from '../../controller/firebase/hook/useFRead';
import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';

import AccountItem from './AccountItem';

import Account from '../../model/Account';
import useFListenWithChildVal from '../../controller/firebase/hook/useFListenWithChildVal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const accountValsToAccountList = (accountVals) => {
    if (!accountVals) return [];
    return Object.entries(accountVals).map(([accountId, rawAccount]) => new Account(rawAccount, accountId, '/account'));
};

export default function AccountList() {
    const {guide} = useGuideContext();
    const {dashFormat} = useDateContext();
    const identifier = dashFormat + (guide?.email ?? '').split('@')[0];
    const {
        isLoading,
        data: accountList,
        error
    } = useFListenWithChildVal(true, '/account', 'identifier', identifier, accountValsToAccountList);
    console.log(identifier, accountList);
    const total = accountList.reduce((rslt, current) => rslt + current.card + current.cash, 0);
    return (
        <Box>

            <List
                sx={{
                    p: 0,
                }}
            >
                {
                    accountList.map(account => (
                        <AccountItem key={account.id} account={account}/>
                    ))
                }
            </List>
        </Box>
    );
}
