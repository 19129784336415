import React, {useCallback, useRef, useState} from "react";
import Box from "@mui/material/Box";
import useFRead from "../../../controller/firebase/hook/useFRead";
import Team from "../../../model/Team";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import PeopleIcon from "@mui/icons-material/People";
import Divider from "@mui/material/Divider";
import Reservation from "../../../model/Reservation";
import {useDateContext} from "../../../controller/context/DateContext";
import {useGuideContext} from "../../../controller/context/GuideContext";
import {callFunction, transaction, updateFireStore} from "../../../controller/firebase";
import copy, {copyToClipboard} from "../../../utils/copy";
import html2canvas from "html2canvas";
import sanitizeFileName from "../../../utils/senitizeFileName";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import QrIcon from "@mui/icons-material/QrCode";
import QRCode from "react-qr-code";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import ButtonBase from "@mui/material/ButtonBase";
import CopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import {IParticipant} from "../../../types/chat";
import {b} from "@fullcalendar/core/internal-common";
import Icon from "@mui/material/Icon";
import CheckIcon from '@mui/icons-material/Check';
import ChattingIcon from '@mui/icons-material/ChatBubble'
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";


const toTeam = (date: string, tourId: string, teamId: string) => (teamVal: any) => {
  if (!teamVal) return null
  return new Team(teamVal, teamId, `/operation/${date}/tours/${tourId}/teams`);
};
export default function (props: {
  date: string,
  tourId: string,
  productId: string,
  teamId: string,
  idx?: number,
  live?: boolean,
  searchKeyword?: string,
  participants: IParticipant[]
}) {
  const {date, productId, tourId, teamId, idx, live, searchKeyword, participants} = props;
  const {
    data: product
  } = useFRead(`/product/${productId ?? tourId}`);
  const {
    data: team,
  } = useFRead(`/operation/${date}/tours/${tourId}/teams/${teamId}`, toTeam(date, tourId, teamId));


  return product && team
    ? (

      <Box sx={{width: '85vw'}}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: '#fff',
          }}
        >
          {/*<Box>*/}
          {/*    <Typography variant={'h6'}>*/}
          {/*        {date}-{productId}*/}
          {/*    </Typography>*/}
          {/*</Box>*/}
          {
            team.pickupPlaces
              .filter(
                (pickupPlace: any) =>
                  (!searchKeyword ||
                    Object.values(pickupPlace.reservations).filter((r: any) =>
                      r.toString().toLowerCase().includes((searchKeyword ?? "").toLowerCase()),
                    ).length > 0),
              )
              .map((pickupPlace: any) => (
                <PickupPart
                  participants={participants}
                  key={pickupPlace.key}
                  pickupPlace={pickupPlace}
                  live={live}
                  isMyTeam={true}
                  belongMyTour={true}
                  searchKeyword={searchKeyword}
                  productId={productId}
                  teamId={teamId}
                  date={date}
                />
              ))
          }
        </Box>
      </Box>
    )
    : null
}

function PickupPart(props: {
  date: string,
  productId: string,
  teamId: string,
  pickupPlace: any,
  live?: boolean,
  isMyTeam?: boolean,
  belongMyTour?: boolean,
  searchKeyword?: string,
  participants: IParticipant[]
}) {
  const {pickupPlace, live, isMyTeam, belongMyTour, searchKeyword, participants, productId, teamId, date} = props;
  const {pickupName, key, people, shownPeople, reservations, adult, kid, infant} = pickupPlace;
  const [timestamp, setTimestamp] = useState(Date.now);
  const sortedReservations = reservations.filter((r: Reservation) => (r.toString().toLowerCase().includes((searchKeyword ?? "").toLowerCase())))
    .sort((a: Reservation, b: Reservation) => {
      if (a.noShow) return 1;
      if (b.noShow) return -1;
      return a.clientName.localeCompare(b.clientName);
    });
  return (
    <Box>
      <ListSubheader
        sx={{
          p: 0,
          zIndex: 800,
          backgroundColor: '#f9f5ff'
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Chip
            sx={{
              border: 'none',
              marginRight: 1,
              '& .MuiChip-label': {
                marginLeft: 0.5,
                paddingRight: 0
              }
            }}
            size={'small'}
            color={'secondary'}
            variant={'outlined'}
            icon={<PeopleIcon/>}
            label={(people !== shownPeople ? `${shownPeople}/${people}` : people) + `(${adult}/${kid}/${infant})`}
          />

          <Typography
            variant={'subtitle1'}
            color={'secondary'}
          >
            {pickupName}({key})
          </Typography>

        </Box>
        <Divider/>
      </ListSubheader>

      {
        sortedReservations.map((reservation: Reservation) => {
          const timeKey = reservation.snapshotedAt.toString();
          const key = `${reservation.id}${timeKey}`
          return (<ReservationItem
            isIn={!!(participants.find((p => (p?.id?.split('::')?.[0] ?? p.id) === reservation.id))?.readAt)}
            participants={participants.filter((p => (p?.id?.split('::')?.[0] ?? p.id) === reservation.id))}
            key={key}
            reservation={reservation}
            live={live}
            isMyTeam={isMyTeam}
            belongMyTour={belongMyTour}
            productId={productId}
            teamId={teamId}
            date={date}
          />)
        })
      }
    </Box>
  )
}

function ReservationItem(props: {
  date: string,
  productId: string,
  teamId: string,
  reservation: Reservation,
  participants?: IParticipant[],
  isIn: boolean,
  live?: boolean,
  isMyTeam?: boolean,
  belongMyTour?: boolean,
}) {
  const navigate = useNavigate();
  const {
    participants,
    reservation,
    isIn,
    live = true,
    isMyTeam = true,
    belongMyTour = true,
    productId,
    teamId,
    date
  } = props;

  const qrRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [qr, setQr] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const isCheckable = live;
  const isControlable = live;

  const noShow = reservation.noShow;

  const handleChatDirect = async (e: any) => {
    if (!window.confirm(`Would you like to open 1:1 chat with ${reservation?.clientName}`)) return;
    setLoadingChat(true);
    try {
      const category = "CLIENT-GUIDE";
      const cId = `${category}:${productId}:${date}:${teamId}:${reservation.id}`
      const title = `${date}:${productId.split("_").pop()}:${reservation.clientName}`
      //
      const chatParams = {
        category,
        participant: {
          id: reservation.id,
          name: reservation.clientName,
          nameEn: "Client",
          type: "client",
          agent: {
            device: '',
            browser: '',
          },
        },
        cId,
        title,
        tour: {
          team: teamId,
          date: date,
          productId: productId,
        },
        reservation: {
          id: reservation.id,
          name: reservation.clientName,
          agencyCode: reservation.agencyCode
        }
      }

      const chatMeta: any = await callFunction('recallComprehensiveTourChat', chatParams);
      navigate(`/chat/rooms/${chatMeta.id}`, {state: {chatMeta, reservation}});

    } catch (e) {
      console.log(e);
      alert('Cannot attend the chat room');
    }
    setLoadingChat(false);
  }

  const handleLiveChecked = (e: any, value: any) => {
    // setChecked(value);
    // todo use security regulation
    const timestamp = Date.now();
    transaction(`${reservation.path}`, (reservation: any) => {
      if (reservation) {
        reservation.check = value;
        reservation.timestamp = timestamp
      }
      return reservation;
    }).then(() => {
      return updateFireStore('reservation', reservation.id, {check: value});
    })
      .catch(e => {
        alert('Please turn on sync when you check reservation.');
      });


  }
  const handleClickMessenger = (e: any) => {
    copy(reservation.messenger);
    window?.prompt('Messenger copied!', reservation.messenger);
  }

  const handleClickQR = (e: any) => {
    setQr((qr) => !qr);
  }

  const handleToggleNoShow = (e: any) => {
    const newNoShow = !noShow;
    // setNoShow(newNoShow);

    transaction(`${reservation.path}`, (reservation: any) => {
      if (reservation) {
        reservation.noShow = newNoShow;
      }
      return reservation;
    })
      .then(() => {
        return updateFireStore('reservation', reservation.id, {noShow: newNoShow})
      })
      .catch(e => {
        console.log(e);
        alert('Please turn on sync when you check reservation.');
      });

  };

  const handleToggleOpen = useCallback((e: any) => {
    setOpen((v) => !v);
  }, [reservation.path]);

  const handleToggleExpand = useCallback((e: any) => {
    setExpand((v) => !v);
  }, [reservation.path]);

  const handleCopyGen = useCallback((text: string) => (e: any) => {
    copy(text);
  }, [reservation.path]);

  const stopPropagation = useCallback((e: any,) => {
    e.stopPropagation();
  }, []);

  const downloadQR = () => {
    const qr = qrRef.current;
    if (!qr) return;

    html2canvas(qr).then((canvas) => {
      // PNG 파일 다운로드
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement('a');
      downloadLink.href = pngFile;
      downloadLink.download = sanitizeFileName(`${reservation.clientName.replace(/\([^)]*\)/g, "")}-${reservation.agencyCode}.png`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  const isAttend = (participants?.length ?? 0) > 0;

  return (
    <ListItem
      sx={theme => ({
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        borderBottom: `solid 1px ${theme.palette.divider}`,
      })}
    >
      <ListItemButton
        sx={{
          py: 0.5,
          px: 2,
          backgroundColor: isAttend ? '#f5fbff' : 'inherit',
        }}
        disableRipple
        disableTouchRipple
        onClick={handleToggleOpen}
      >
        <ListItemIcon
          sx={{
            minWidth: 0
          }}
          onClick={stopPropagation}
        >
          <Checkbox
            edge="start"
            disabled={!isCheckable}
            defaultChecked={reservation.check}
            // checked={checked}

            onChange={handleLiveChecked}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            marginLeft: 0.5
          }}
          primaryTypographyProps={
            {component: 'div'}
          }
          secondaryTypographyProps={
            {component: 'div'}
          }

          primary={
            <Box>
              <Box display={'flex'}
                   justifyContent={'space-between'}
                   alignItems={'center'}
                   marginBottom={0.5}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Typography
                    fontWeight={'500'}
                    variant={'body1'}
                    color={
                      isAttend ? 'primary'
                        : noShow ? 'grey.400' : 'default'
                    }
                    display={'flex'}
                    alignItems={'center'}
                  >
                    {
                      isAttend
                      &&
                        <Icon fontSize={'small'} color={'inherit'}>
                          check
                        </Icon>
                    }
                    {reservation.clientName.replace(/\([^)]*\)/g, "")}
                  </Typography>

                </Box>
                <Chip size={'small'}
                      avatar={<Avatar>{reservation.agency}</Avatar>}
                      label={
                        <Typography
                          sx={{maxWidth: '120px'}}
                          variant={'body2'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                          color={noShow ? 'grey.400' : 'default'}
                        >
                          {reservation.agencyCode}
                        </Typography>
                      }
                      color={'primary'}
                      variant={'outlined'}
                      sx={{
                        flexDirection: 'row-reverse',
                        border: 'none',
                        color: 'inherit',
                        fontWeight: '500'
                      }}
                />
              </Box>
            </Box>
          }
          secondary={
            <Grid container spacing={0.5}>
              <Grid item xs={6}>
                <Typography
                  component={'p'}
                  variant={'body2'}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  fontWeight={'500'}
                  color={noShow ? 'grey.400' : 'default'}
                >
                  {`${reservation.people} `}
                  ({`${reservation.adult}/${reservation.kid}/${reservation.infant}`})

                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component={'p'}
                  variant={'body2'}
                  textAlign={'right'}
                  whiteSpace={'nowrap'}
                  color={noShow ? 'grey.400' : 'default'}
                >
                  <Typography
                    component={'span'}
                    variant={'body2'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    mx={1}
                  >
                    {reservation.nationality}
                  </Typography>
                  <Box
                    component={'span'}
                    display={'inline-block'}
                    width={'18px'}
                    height={'18px'}
                  >
                    {reservation.nationalityFlag}
                  </Box>
                </Typography>

              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*    <Typography*/}
              {/*        component={'p'}*/}
              {/*        variant={'caption'}*/}
              {/*        textOverflow={'ellipsis'}*/}
              {/*        overflow={'hidden'}*/}
              {/*        whiteSpace={'nowrap'}*/}
              {/*        color={noShow ? 'grey.400' : 'default'}*/}
              {/*    >*/}
              {/*        {reservation.tel} <br/>*/}
              {/*    </Typography>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={6}>*/}
              {/*    <Typography*/}
              {/*        component={'p'}*/}
              {/*        variant={'caption'}*/}
              {/*        textAlign={'right'}*/}
              {/*        textOverflow={'ellipsis'}*/}
              {/*        overflow={'hidden'}*/}
              {/*        whiteSpace={'nowrap'}*/}
              {/*        color={noShow ? 'grey.400' : 'default'}*/}
              {/*    >*/}
              {/*        {reservation.email} <br/>*/}
              {/*    </Typography>*/}
              {/*</Grid>*/}
              {/*{*/}
              {/*    reservation.messenger && reservation.messenger.length > 5*/}
              {/*        ? (*/}
              {/*            <Grid item xs={12}>*/}
              {/*                <Typography*/}
              {/*                    component={'p'}*/}
              {/*                    variant={'caption'}*/}
              {/*                    textOverflow={'ellipsis'}*/}
              {/*                    overflow={'hidden'}*/}
              {/*                    whiteSpace={'nowrap'}*/}
              {/*                >*/}
              {/*                    {reservation.messenger} <br/>*/}
              {/*                </Typography>*/}
              {/*            </Grid>*/}
              {/*        )*/}
              {/*        : null*/}
              {/*}*/}
              <Grid item xs={10}>
                {
                  (reservation.option ?? []).map((option) => (
                    <Stack flexDirection={'row'} gap={1}>
                      <Typography
                        component={'p'}
                        variant={'body2'}
                        color={'primary'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                      >
                        {option.option}
                      </Typography>
                      <Typography
                        component={'p'}
                        variant={'body2'}
                        color={'primary'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textAlign={'end'}
                      >
                        {option.people}
                      </Typography>
                    </Stack>
                  ))
                }
              </Grid>
              <Grid item xs={2}>
                <Stack
                  flexDirection={'row-reverse'}
                  gap={1}
                >

                  {
                    reservation.language.split(',').map((l: string) => l.trim()).map((language: string) => (

                        <Box
                          color={'white'}
                          sx={{
                            opacity: noShow ? 0.15 : 1,
                            px: 0.5,
                            borderRadius: 1,
                            backgroundColor: language === 'ENGLISH'
                              ? '#002868'
                              : language === 'CHINESE'
                                ? '#FF4E20'
                                : '#1ba242'
                          }}
                        >
                          <Typography
                            component={'p'}
                            variant={'body2'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                            whiteSpace={'nowrap'}
                            textAlign={'right'}
                            color={'inherit'}
                          >
                            {
                              language === 'ENGLISH'
                                ? 'EN'
                                : language === 'CHINESE'
                                  ?
                                  'CN'
                                  : language === 'JAPANESE'
                                    ? 'JP'
                                    : language === 'KOREAN'
                                      ?
                                      'KO'
                                      : language

                            }
                          </Typography>
                        </Box>
                      )
                    )}

                  <Box>
                    <Typography
                      component={'p'}
                      variant={'body2'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      textAlign={'right'}
                      color={noShow ? 'grey.400' : 'secondary.main'}
                    >
                      {reservation.stroller ? '유모차' : ''}
                    </Typography>
                  </Box>

                </Stack>

              </Grid>
              <Grid item xs={12}>
                <Typography
                  component={'p'}
                  variant={'body2'}
                  color={'secondary'}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                >
                  {reservation.memo}
                </Typography>
              </Grid>
              {
                (participants?.length ?? 0) > 0 &&
                  <Grid item xs={12}>
                    <Box
                        sx={{
                          border: 'solid 1px rgba(0,0,0,0.1)',
                          borderRadius: '4px',
                          p: '4px',
                          mt: '4px'
                        }}
                    >
                      <Typography
                          variant={'body2'}
                          fontWeight={'medium'}
                          gutterBottom
                      >
                        Group Member
                      </Typography>
                      <Grid container spacing={0.5}>
                        {
                          (participants ?? []).map((p) => (
                            <Grid item xs={12}
                                  key={p.id}>
                              <Typography
                                component={'p'}
                                variant={'body2'}
                                fontWeight={'medium'}
                                color={'typography.primary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'pre-wrap'}
                              >
                                {p.name}({p.readAt?.toLocaleString() ?? 'Not yet read'} )
                              </Typography>
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Box>
                  </Grid>
              }
            </Grid>
          }
        />
      </ListItemButton>
      <Collapse
        unmountOnExit
        in={open}
        timeout={100}
      >
        <Box
          sx={{
            paddingTop: 0.5,
            paddingBottom: 1,
            px: 2,
            backgroundColor: '#f9f9fd',
            textAlign: 'center'
          }}
        >
          <Button
            fullWidth
            color={'primary'}
            startIcon={<QrIcon/>}
            variant={'outlined'}
            onClick={handleClickQR}
            sx={{
              marginBottom: 1.5
            }}
          >
            TOUR CHAT (QR,LINK)
          </Button>
          {
            qr ?
              <Box
                sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center'}}
              >
                <Box
                  ref={qrRef}
                  sx={{
                    width: '100%',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    backgroundColor: 'white',
                    p: 3,
                  }}
                >
                  <QRCode
                    value={
                      reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                        `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                        : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`}/>
                  <Typography fontWeight={"bold"}>
                    {reservation.clientName.replace(/\([^)]*\)/g, "")}<br/>
                    {reservation.agencyCode}
                  </Typography>
                </Box>
                <Box
                  width={'100%'}
                  px={1}
                >
                  <TextField fullWidth disabled variant={'standard'} value={
                    reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                      `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                      : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`}/>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button variant={'text'} onClick={downloadQR}>
                    DOWNLOAD QR
                  </Button>
                  <Button onClick={() => {
                    copy(
                      reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                        `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                        : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`)
                  }}>
                    COPY LINK
                  </Button>
                </Box>
              </Box>
              : null
          }

          {/*<Button*/}
          {/*    fullWidth*/}
          {/*    color={'primary'}*/}
          {/*    startIcon={loadingChat ? <CircularProgress size={24}/> : <ChatIcon/>}*/}
          {/*    variant={'outlined'}*/}
          {/*    onClick={handleChatDirect}*/}
          {/*    sx={{*/}
          {/*        marginBottom: 1.5*/}
          {/*    }}*/}
          {/*>*/}
          {/*    CHAT DIRECT*/}

          {/*</Button>*/}

          <Button
            disabled={(reservation.tel?.length ?? 0) < 5}
            fullWidth
            component={'a'}
            href={`tel:${reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}`}
            color={'primary'}
            startIcon={<PhoneIcon/>}
            variant={'outlined'}
            sx={{
              marginBottom: 1.5
            }}
          >
            {reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}
          </Button>
          <Button
            fullWidth
            disabled={(reservation.email?.length ?? 0) < 5}
            component={'a'}
            href={`mailto:${reservation.email}?subject="Contact From Today's Tour"&body="Hello!"}`}
            color={'secondary'}
            startIcon={<EmailIcon/>}
            variant={'outlined'}
            sx={{
              marginBottom: 1.5
            }}
          >
            {reservation.email}
          </Button>
          <Button
            fullWidth
            disabled={(reservation.messenger?.length ?? 0) < 5}
            color={'secondary'}
            startIcon={<ChatIcon/>}
            variant={'outlined'}
            onClick={handleClickMessenger}
            sx={{
              marginBottom: 1.5
            }}
          >
            {reservation.messenger}
          </Button>
          <Button
            fullWidth
            disabled={!isControlable}
            color={'error'}
            variant={'outlined'}
            onClick={handleToggleNoShow}
            sx={{
              marginBottom: 1.5
            }}
          >
            {noShow ? 'Check In' : 'No Show'}
          </Button>

          <Collapse
            unmountOnExit
            in={expand}
            timeout={100}
          >
            <Box
              sx={{
                paddingTop: 1,
                paddingBottom: 2,
                backgroundColor: '#f9f9fd',
                textAlign: 'left'
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <ButtonBase
                    onClick={handleCopyGen(`${reservation.clientName}(${reservation.people})`)}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0.5,
                    }}
                  >
                    <Typography
                      variant={'body2'}
                      whiteSpace={'pre-wrap'}
                      component={'span'}
                    >
                      {reservation.clientName}({reservation.people})
                    </Typography>
                    <CopyIcon
                      sx={{
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBase
                    onClick={handleCopyGen(reservation.agencyCode)}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0.5
                    }}
                  >
                    <Typography
                      variant={'body2'}
                      whiteSpace={'pre-wrap'}
                      component={'span'}
                    >
                      {reservation.agencyCode}({reservation.agency})
                    </Typography>
                    <CopyIcon
                      sx={{
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBase
                    onClick={handleCopyGen(reservation.tel)}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0.5
                    }}
                  >
                    <Box>
                      <Typography
                        variant={'body2'}
                        whiteSpace={'pre-wrap'}
                        component={'span'}
                      >
                        {reservation.tel}
                      </Typography>
                    </Box>
                    <CopyIcon
                      sx={{
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBase
                    onClick={handleCopyGen(reservation.messenger)}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0.5
                    }}
                  >
                    <Typography
                      variant={'body2'}
                      whiteSpace={'pre-wrap'}
                      component={'span'}
                    >
                      {reservation.messenger}
                    </Typography>
                    <CopyIcon
                      sx={{
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBase
                    onClick={handleCopyGen(reservation.email)}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0.5
                    }}
                  >
                    <Typography
                      variant={'body2'}
                      whiteSpace={'pre-wrap'}
                      component={'span'}
                    >
                      {reservation.email}
                    </Typography>
                    <CopyIcon
                      sx={{
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </ButtonBase>
                </Grid>
                {
                  reservation.memo
                    ? (<Grid item xs={12}>
                        <ButtonBase
                          onClick={handleCopyGen(reservation.memo)}
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            py: 0.5
                          }}
                        >
                          <Typography
                            variant={'body2'}
                            whiteSpace={'pre-wrap'}
                            component={'span'}
                          >
                            {reservation.memo}
                          </Typography>
                          <CopyIcon
                            sx={{
                              width: '18px',
                              height: '18px'
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                    ) : null
                }
              </Grid>
            </Box>
          </Collapse>
          <ButtonBase
            onClick={handleToggleExpand}
            sx={{
              width: '100%',
              py: 0,
              transform: `rotate(${expand ? 180 : 360}deg)`
            }}
          >
            <ExpandMoreIcon/>
          </ButtonBase>
        </Box>
      </Collapse>
    </ListItem>
  );
}