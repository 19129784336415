import {Grid, Typography} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import {useContext, useEffect, useState} from 'react';
import {DrawerContext} from '../../../context/DrawerContext';
import {getDocData} from '../../../controller/chat/firebase';
import {IParticipant, ITour} from '../../../types/chat';

export default function ChatHeader() {
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [category, setCategory] = useState<string | null>(null);
  const [tour, setTour] = useState<ITour | null>(null);
  const [title, setTitle] = useState<string>('');

  const navigate = useNavigate();

  const location = useLocation();
  const receivedState = location.state;

  const {chatId} = useParams() as { chatId: string };

  const {state: drawerState, dispatch: dispatchDrawer} = useContext(DrawerContext);

  useEffect(() => {
    async function getParticipants() {
      const {participants, title, category, tour, readDate, ...others} = (await getDocData(['chats', chatId])) as any;
      const readDateIds = Object.keys(readDate ?? {});
      setParticipants(Object.values(participants ?? {}).map((p) => {
        const participant = p as IParticipant;
        const readDateVal = readDate?.[participant.id];
        const dateVal = readDateVal ? typeof readDateVal.toDate === 'function' ? readDateVal.toDate() : new Date(readDateVal) : undefined;
        participant.readAt = dateVal;
        return participant;
      }));
      setTitle(title);
      setTour(tour);
      setCategory(category);
    }
    getParticipants();
  }, [drawerState, chatId]);

  return (
    <Grid
      container
      sx={{
        position: 'sticky',
        top: 56,
        background: '#fff',
        zIndex: 1,
        py: 1.25,
        px: 2.5,
      }}
    >
      <Grid item xs={1} display="flex" alignItems="center">
        <ChevronLeftIcon
          sx={{
            width: '32px',
            height: '32px',
          }}
          onClick={() => {
            navigate('/chat/rooms', {state: {date: tour?.date ?? '', ...receivedState}})
          }}
        />
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1} alignItems="center" display="flex" justifyContent="flex-end">
        <GroupIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            dispatchDrawer({
              type: 'OPEN',
              payload: {
                view: 'CHAT_TEAM',
                anchor: 'right',
                data: {participants, category, tour},
              },
            });
          }}
        />
      </Grid>
    </Grid>
  );
}
