import { ICreateChatParams, IGuide, IMessage, IOperator, IParticipant, ISender, ITour, IUser } from '../../types/chat';
import { getDocData } from './firebase';

export const parseChatMetadata = ({
    participants,
    category,
    tour,
    title,
}: {
    participants: IParticipant[];
    category: 'client' | 'personal' | 'all' | 'group' | 'team' | 'tour';
    title: string;
    tour?: ITour;
}): ICreateChatParams => {
    const cId = participants
        .map((participant) => participant.id)
        .sort((a, b) => (a > b ? 1 : 0))
        .join('');

    // if (tour) {
    //     const title = `${tour.date}:${tour.productId.split('_').pop()}:${participants.length}`;
    // }

    return {
        category,
        participants,
        cId,
        title,
        tour: tour ?? {},
    };
};

export const isInfoMessage = (message: IMessage) => {
    switch (message.type) {
        case 'exit':
        case 'enter':
        case 'date':
            return true;
        default:
            return false;
    }
};

export const separateMessages = (messages: IMessage[]): IMessage[][] => {
    const separatedMessages: IMessage[][] = [];
    let messageGroup: IMessage[] = [];
    let prevSenderId: string | null = null;
    let lastMinute: number = 0;

    messages.forEach((message: IMessage, index: number) => {
        const { type, sender, date: messageDate } = message;

        const nowDate = new Date(messageDate.seconds * 1000);
        const currentMinute = nowDate.getHours() * 60 + nowDate.getMinutes();

        if (type === 'exit' || type === 'enter' || type === 'date') {
            if (messageGroup.length > 0) {
                separatedMessages.push(messageGroup);
                messageGroup = [];
            }
            separatedMessages.push([message]);
            messageGroup = [];
            return;
        }
        if (messageGroup.length > 0 && (currentMinute !== lastMinute || prevSenderId !== sender.id)) {
            separatedMessages.push(messageGroup);
            messageGroup = [];
        }

        messageGroup.push(message);
        lastMinute = currentMinute;
        prevSenderId = sender.id;

        if (index === messages.length - 1) {
            if (messageGroup.length > 0) {
                separatedMessages.push(messageGroup);
            }
        }
    });

    return separatedMessages;
};

export const convertSenderName = (sender: ISender, reverse?: boolean) => {
    const nameEn = sender.nameEn?.replace(/\(.*?\)/g, '') ?? '';
    const name = sender.name?.replace(/\(.*?\)/g, '') ?? '';
    const unknwonSender = sender as unknown as {reservationName?:string};
    return reverse ? `${name}(${unknwonSender.reservationName ?? nameEn}) ` : `${nameEn}(${unknwonSender.reservationName ?? name})`;
};

export const isParticipant = async (
    chatId: string,
    id: string,
): Promise<{
    isExist: boolean;
    participants: {
        [id: string]: IParticipant;
    };
}> => {
    const doc = (await getDocData(['chats', chatId])) as any;
    if(!doc  || !doc.participants) return {isExist:false, participants: {}};
    const participants = doc.participants;
    const isExist = Object.keys(participants).includes(id);
    return {
        isExist,
        participants,
    };
};

export const parseParticipantData = (user: IUser | IGuide | IOperator): IParticipant => {
    const { id, name, nameEn, raw, level } = user;

    return {
        id,
        name,
        nameEn,
        type: level >= 1 ? 'guide' : 'operator',
    };
};
