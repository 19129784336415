import {useEffect, createContext, useContext, useState} from "react";
import {
  login,
  loginWithGoogle,
  logout,
  read,
  listen,
  onAuthStateChange,
  register,
  reset,
  removeUserToken
} from "../firebase";

export const GuideContext = createContext({
  isInitialized: false,
  guide: null,
  guideMap: new Map(),
  login,
  loginWithGoogle,
  logout,
  register,
  reset,
  user: null,
});

export function useGuideContext() {
  return useContext(GuideContext);
}

export function GuideContextProvider({children}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [guide, setGuide] = useState(null);
  const [guideMap, setGuideMap] = useState(null);
  const [user, setUser] = useState(null);
  const defineGuide = async (user) => {
    const guideMap = await getGuideMap();
    const guide = guideMap.get(user.email);
    setGuide({
      ...guide,
      admin: guide.level <= 1,
      cs: guide.level === 1.25,
      promotion: guide.email === 'cs@ktourstory.com',
      outsource: guide.level === 1.5,
      bus: guide.email === 'ktourstory.bus@gmail.com',
      uid: guide.uid ?? guide.id
    });
    setGuideMap(guideMap);
  };

  const handleAuthChanged = (user) => {
    setUser(user ?? null);
    if (user) {
      defineGuide(user)
          .then(() => {
            setIsInitialized(true);
          })
          .catch((e) => {
            console.log(e);
            console.log("fail on read guide");
          });
    } else {
      removeUserToken().catch(console.error);
      setGuide(null);
      setGuideMap(null);
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    onAuthStateChange(handleAuthChanged);
  }, []);

  useEffect(() => {
    const unsubscribe = guide?.id ? listen(`/user/${guide.uid}`, (snapshot) => {
      const user = snapshot.val();
      if (user?.level >= 3) {
        setGuide(null);
        setGuideMap(null);
        // logout();
      } else {
        handleAuthChanged(user);
      }
    }) : () => {
    }
    return () => {
      unsubscribe();
    }
  }, [guide?.uid]);

  // return ({children}) => {
  return (
      <GuideContext.Provider
          value={{
            isInitialized,
            guide,
            guideMap,
            login,
            loginWithGoogle,
            logout,
            register,
            reset,
            user,
          }}
      >
        {children}
      </GuideContext.Provider>
  );
  // };
}

async function getGuideMap() {
  const snapshot = await read("/user");
  const _guides = snapshot.val();
  const entries = Object.entries(_guides);
  const emailEntries = entries.map(([gid, guide]) => [guide.email, guide]);
  return new Map(entries.concat(emailEntries));
}
