import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {Box, Input, Switch, ToggleButton} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {Space} from '../../components/ui/Space';
import Footer from '../../components/chat/layout/Footer';
import {GuideContext} from '../../controller/context/GuideContext';

import {subscribeToAllUnreadMessages, subscribeToOwnChats} from '../../controller/chat';
import ChatRoom from '../../components/chat/chats/ChatRoom';
import useFRead from '../../controller/firebase/hook/useFRead';
import {callFunction} from '../../controller/firebase';
import Tour from '../../model/Tour';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {useLocation} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import {readRealtime} from "../../controller/chat/firebase";
import {IChatMeta} from "../../types/chat";

const ALL = 'ALL';
const UNREAD = 'UNREAD';
const CLIENT = 'CLIENT';

const operationToTourList = (date: string) => (operationVal: any) => {
  if (!operationVal) return [];
  return Object.entries(operationVal).map(
    ([tourId, rawTour]) => new Tour(rawTour, tourId, `/operation/${date}/tours`),
  );
};

const findMyTourAndTeam = (tours: Tour[], guideId?: string) => {
  if (tours.length === 0 || !guideId) return {tours: [], team: null};
  const {myTours} = tours.reduce(
    (result, tour) => {
      if (tour.teamInfos.guideSet.has(guideId)) {
        result.myTours.push(tour);
      } else {
        result.otherTours.push(tour);
      }
      return result;
    },
    {otherTours: [] as Tour[], myTours: [] as Tour[]},
  );

  const myTeam = myTours[0]?.teamList.find((team) => team.guides.includes(guideId));
  const splited = myTeam?.path?.split('/') ?? [];

  return {
    tours: myTours,
    team: myTeam,
    date: splited?.[2],
    productId: splited?.[4],
    teamId: splited?.[6],
  };
};

export default function Chats() {
  const {guide} = useContext(GuideContext) as any;

  const location = useLocation();
  const receivedState = location.state || {};
  const prevDate = receivedState?.date;
  const prevTab = receivedState?.tab;
  const prevProduct = receivedState?.product;
  const prevDateType = receivedState?.dateType;

  const [chats, setChats] = useState<any>({});
  const [unreadChats, setUnreadChats] = useState<any>({});
  const [noFilter, setNoFilter] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(prevTab ? prevTab : UNREAD);
  const [date, setDate] = useState<string>(prevDate ?? dayjs().format('YYYY-MM-DD'));
  const [product, setProduct] = useState<string>(prevProduct ?? ALL);

  const [todayChat, setTodayChat] = useState<any>(null);
  const [tomorrowChat, setTomorrowChat] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingToday, setLoadingToday] = useState<boolean>(false);
  const [loadingTomorrow, setLoadingTomorrow] = useState<boolean>(false);
  const [dateType, setDateType] = useState<'tourDate' | 'lastMessageDate'>(prevDateType ?? (guide.promotion ? 'lastMessageDate' : 'tourDate'));

  const today = dayjs().format('YYYY-MM-DD');
  const tomorrow = dayjs().add(1, 'days').format('YYYY-MM-DD');

  const todayToursMapper = useMemo(() => operationToTourList(today), [today]);
  const tomorrowToursMapper = useMemo(() => operationToTourList(tomorrow), [tomorrow]);
  const productsMapper = useMemo(() => (f: any) => (f as { [productId: string]: any }), []);

  const {data: todayTours} = useFRead(`/operation/${today}/tours`, todayToursMapper, []);
  const {data: tomorrowTours} = useFRead(`/operation/${tomorrow}/tours`, tomorrowToursMapper, []);
  const {data: products} = useFRead('/product', productsMapper, {});

  const myTodayTourInfo = useMemo(() => findMyTourAndTeam(todayTours, guide?.id), [todayTours, guide?.id]);
  const myTomorrowTourInfo = useMemo(() => findMyTourAndTeam(tomorrowTours, guide?.id), [tomorrowTours, guide?.id]);

  useEffect(() => {
    if (!guide?.id) return;
    setLoading(true);
    const collectThrough = dateType === 'tourDate' ? 'tour.date' : 'lastMessage.yyyymmdd';
    const unsub = subscribeToOwnChats(guide.id, date, collectThrough,
      (querySnapshot) => {
        let chats: any = {};
        querySnapshot.docs.forEach(async (doc: any) => {
          const chat = doc.data();
          chats[doc.id] = {
            ...chat,
            title: chat.title,
          };
        });
        setChats(chats);
        setLoading(false);
      }, (error) => {
        alert(`채팅을 불러오는 중 에러가 발생했습니다.`)
        console.log(error);
        setLoading(false);
      })

    const unsubUnread = subscribeToAllUnreadMessages(guide.id, (querySnapshot) => {
      let unreadChats: any = {};
      querySnapshot.docs.forEach(async (doc: any) => {
        const chat = doc.data();
        unreadChats[doc.id] = {
          ...chat,
          title: chat.title,
        };
      })
      setUnreadChats(unreadChats);
    })

    return () => {
      if (unsub) unsub();
      if (unsubUnread) unsubUnread();
    };
  }, [guide?.id, date, dateType]);

  const recallChat = async (date: string, productId: string, teamId: string) => {
    try {
      const comprehensiveChatParams = {
        category: 'CLIENT',
        participant: {
          id: guide.id,
          name: guide.name,
          nameEn: guide.nameEn ?? guide.name,
          photoURL: '',
          type: 'guide',
        },
        cId: `CLIENT:${productId}:${date}:${teamId}`,
        title: `${date}:${productId.split('_').pop()}`,
        tour: {
          date,
          productId,
          team: teamId,
        },
      };

      return await callFunction('recallComprehensiveTourChat', comprehensiveChatParams);
    } catch (e) {
      console.log(e);
      alert('Chat is not attendable');
    }
  };

  useEffect(() => {
    if (!myTodayTourInfo?.productId) return;
    const product = products?.[myTodayTourInfo.productId];
    if (!product || product.category.toLowerCase().includes('private')) return;
    if (!myTodayTourInfo?.date || !myTodayTourInfo?.productId || !myTodayTourInfo?.teamId) return;

    setLoadingToday(true);
    recallChat(myTodayTourInfo.date, myTodayTourInfo.productId, myTodayTourInfo.teamId)
      .then((chat) => {
        if ((chat as any).success === false) throw new Error('Chat is not recallable.')
        setTodayChat(chat);
      })
      .catch((e) => {
        alert('Cannot load tour chat for today');
      })
      .finally(() => {
        setLoadingToday(false);
      });
  }, [myTodayTourInfo?.teamId, myTodayTourInfo?.productId, products]);

  useEffect(() => {
    if (!myTomorrowTourInfo?.productId) return;
    const product = products?.[myTomorrowTourInfo.productId];
    if (!product || product.category.toLowerCase().includes('private')) return;
    if (!myTomorrowTourInfo?.date || !myTomorrowTourInfo?.productId || !myTomorrowTourInfo?.teamId) return;
    if (dayjs().hour() < 14) return;

    setLoadingTomorrow(true);
    recallChat(myTomorrowTourInfo.date, myTomorrowTourInfo.productId, myTomorrowTourInfo.teamId)
      .then((chat) => {
        if ((chat as any).success === false) throw new Error('Chat is not recallable.')
        setTomorrowChat(chat);
      })
      .catch((e) => {
        alert('Cannot load tour chat for tomorrow');
      })
      .finally(() => {
        setLoadingTomorrow(false);
      });
    ;
  }, [myTomorrowTourInfo?.teamId, myTomorrowTourInfo?.productId, products]);

  const aggregated = Object.entries(chats).reduce(
    (result, [key, chat]: [string, any]) => {
      const category = chat.category;
      if (!result[category]) result[category] = {};
      result[category][chat.id] = chat;
      result[ALL][chat.id] = chat;

      if (chat.tour && chat.tour.date) {
        const date = chat.tour.date;
        const product = chat.tour.productId;
        if (!result['_date'][date]) result['_date'][date] = {};
        if (!result['_date'][date][product]) result['_date'][date][product] = {};

        result['_date'][date][product][chat.id] = chat;
      }

      return result;
    },
    {[ALL]: {}, _date: {}} as { [type: string]: { [chatId: string]: any } },
  );

  const productOptions = Object.keys(aggregated['_date'][date] ?? {});
  const todayChats = Object.values(chats).filter((chat: any) => chat.title?.includes(today) && chat.id !== todayChat?.id);
  const unreadChatList = Object.values<any>(unreadChats);

  const checkHighlight = (chat: any) => {
    return (chat.lastMessage?.type !== 'exit') && ((chat.lastMessage?.count ?? 2) > 2 || dayjs(chat.lastMessage?.date.toDate()).diff(dayjs(chat.createdAt.toDate()), "seconds") > 10);
  }


  return loading
    ? (
      <Box py={20} px={4}>
        <LinearProgress/>
      </Box>
    )
    : (
      <>


        <Box
          sx={{
            px: 4,
            mt: 2,
            my: 2,
          }}
        >
          <Typography fontWeight={'bold'} variant={'h6'} color={'primary'}>
            Today's
          </Typography>
          {
            loadingToday
              ? (
                <Box
                  sx={{
                    px: 4,
                    mt: 2,
                    my: 2,
                  }}
                >
                  <LinearProgress/>
                </Box>
              )
              : todayChat
                ? (
                  <ChatRoom key={todayChat.id} chatMeta={todayChat} products={products} chatsState={{dateType, date, product, tab}}/>
                )
                : (
                  <Typography fontWeight={'bold'} textAlign={'center'}>
                    No Tour Chat Today
                  </Typography>
                )
          }
          {
            todayChats.map((c: any) => {
              return (
                <ChatRoom key={c.id} chatMeta={c} products={products} chatsState={{dateType, date, product, tab}}
                />
              )
            })
          }
        </Box>
        {
          myTomorrowTourInfo.team && dayjs().hour() >= 14
            ? (
              <Box
                sx={{
                  px: 4,
                  py: 2,
                }}
              >
                <Typography fontWeight={'bold'} variant={'h6'} color={'primary'}>
                  Tomorrow's
                </Typography>
                {
                  loadingTomorrow ?
                    (
                      <Box
                        sx={{
                          px: 4,
                          mt: 2,
                          my: 2,
                        }}
                      >
                        <LinearProgress/>
                      </Box>

                    )
                    : tomorrowChat
                      ? (
                        <ChatRoom key={tomorrowChat.id} chatMeta={tomorrowChat} products={products} chatsState={{dateType, date, product, tab}}/>
                      )
                      : (
                        <Typography fontWeight={'bold'} textAlign={'center'}>
                          No Chat Tomorrow
                        </Typography>
                      )
                }
              </Box>
            )
            : null}
        <Tabs
          sx={{
            px: 4,
          }}
          variant={'scrollable'}
          scrollButtons={'auto'}
          value={tab}
          onChange={(_, newTab) => {
            setTab(newTab);
          }}
        >
          {[UNREAD, ALL]
            .map((t) => {
              const label = t;
              return <Tab value={t} key={t} label={label}/>;
            })}
        </Tabs>
        <Divider/>

        <Space height={20}/>

        {
          tab === UNREAD
            ? (unreadChatList.length > 0
                ? (
                  <Box display="flex" flexDirection="column" gap="12px" px={4} pb={12}>
                    {
                      unreadChatList
                        .sort((aChat, bChat) => {
                          return aChat.updatedAt?.seconds > bChat.updatedAt?.seconds ? -1 : 0;
                        })
                        .map((chat) => {
                          return <ChatRoom key={chat.id} chatMeta={chat} products={products}
                                           chatsState={{dateType, date, product, tab}}
                          />;
                        })
                    }
                  </Box>
                )
                : (<Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Typography variant={'h6'}>No unread messages</Typography>
                </Box>)
            )
            : (
              <>
                <Box mx={4.5} mb={1}>
                  <FormControlLabel
                    control={
                      <Switch checked={dateType === 'lastMessageDate'}
                              onChange={(e, value) => {
                                setDateType(value ? 'lastMessageDate' : 'tourDate');
                              }}
                              name="gilad"/>
                    }
                    label={
                      <Typography fontWeight={'bold'}>
                        {dateType === 'lastMessageDate' ? "By Last Message Date" : 'By Tour Date'}
                      </Typography>
                    }
                  />
                </Box>
                <Box display={'flex'} justifyContent={'space-evenly'} mx={4.5} mb={3} gap={2}>
                  <DatePicker
                    date={new Date(date)}
                    onChange={(date) => {
                      if (!date) return;
                      setDate(dayjs(date).format('YYYY-MM-DD'));
                    }}
                    guide={guide}
                  />
                  <Select
                    fullWidth
                    variant={'standard'}
                    value={product}
                    onChange={(e) => {
                      setProduct(e.target.value);
                    }}
                  >
                    <MenuItem value={ALL}>{ALL}</MenuItem>
                    <MenuItem value={UNREAD}>{UNREAD}</MenuItem>
                    {productOptions.sort((a, b) => a > b ? 1 : -1).map((p) => (
                      <MenuItem value={p}>{p.toUpperCase()}</MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box display="flex" flexDirection="column" gap="12px" px={4} pb={4}>
                  {guide &&
                    Object.values(
                      {
                        ...aggregated[tab],
                      } ?? [],
                    )
                      .sort((aChat, bChat) => {
                        return aChat.updatedAt?.seconds > bChat.updatedAt?.seconds ? -1 : 0;
                      })
                      .filter((chat) => {
                        return checkHighlight(chat)
                        // return chat.lastMessage?.count > 2 && (product === null || product === ALL || chat.tour?.productId === product);
                      })
                      .map((chat) => {
                        // const chat = aggregated[tab][chatId];
                        return <ChatRoom key={chat.id}
                                         highlight
                                         chatMeta={chat}
                                         products={products}
                                         chatsState={{dateType, date, product, tab}}
                        />;
                      })}
                </Box>
                <Box>
                  <Divider/>
                </Box>
                <Box display="flex" flexDirection="column" gap="12px" px={4} py={4}>
                  {guide &&
                    Object.values(
                      {
                        ...aggregated[tab],
                      } ?? [],
                    )
                      .sort((aChat, bChat) => {
                        return aChat.updatedAt?.seconds > bChat.updatedAt?.seconds ? -1 : 0;
                      })
                      .filter((chat) => {
                        return !checkHighlight(chat)
                      })
                      .map((chat) => {
                        // const chat = aggregated[tab][chatId];
                        return <ChatRoom key={chat.id}
                                         chatMeta={chat}
                                         products={products}
                                         chatsState={{dateType, date, product, tab}}
                        />;
                      })}
                </Box>
              </>
            )
        }
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
          }}
        >
          {
            guide.promotion
              ? null
              : (<Footer/>)
          }

        </Box>
      </>
    );
}


function DatePicker({date, onChange, guide}: { date: Date, onChange: (date: Date | null) => void, guide: any }) {
  const [_date, _setDate] = useState<Date | null>(date)
  const handleChangeBarDate = useCallback((datelike: Date | null) => {
    if (datelike === null) return null;

    if (guide.admin || guide.outsource || guide.cs) {
      return _setDate(datelike)
    }

    const newDate = new Date(datelike);
    // todo 106 -> 10
    if (newDate > new Date(Date.now() + (10 + new Date().getHours()) * 60 * 60 * 1000)) {
      alert("Not yet deployed")
      return null
    }
    _setDate(datelike)
  }, [])

  return (
    <MobileDatePicker
      value={_date}
      onAccept={onChange}
      onChange={handleChangeBarDate}
      inputFormat={"MM/DD"}
      showToolbar={false}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CalendarIcon/>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
