import {createContext, PropsWithChildren, useContext, useEffect, useReducer} from 'react';
import {Drawer} from '@mui/material';
import {useLocation} from 'react-router-dom';
import AddParticipant from '../components/chat/drawer/AddParticipant';
import ChatTeam from "../components/chat/drawer/ChatTeam";
import {ITour} from "../types/chat";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const DrawerContext = createContext<any>(null);

export const DrawerContextProvider = ({children}: PropsWithChildren) => {
    const INITIAL_STATE = {
        open: false,
        view: null,
        anchor: 'bottom',
        data: null,
    };

    const drawerReducer = (state: any, action: any) => {
        switch (action.type) {
            case 'OPEN':
                return {
                    open: true,
                    view: action.payload.view,
                    anchor: action.payload.anchor,
                    data: action.payload.data,
                };
            case 'CLOSE':
                return {
                    ...state,
                    open: false,
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(drawerReducer, INITIAL_STATE);

    return (
        <DrawerContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </DrawerContext.Provider>
    );
};

export const DrawerView = () => {
    const {state, dispatch} = useContext(DrawerContext);
    const location = useLocation();

    const threeDaysAgo = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
    const chatDate = state?.data?.tour?.date? new Date(state?.data?.tour?.date) : new Date(0)

    useEffect(() => {
        dispatch({type: 'CLOSE'});
    }, [location.pathname]);

    const {participants, category, tour, searchKeyword,} = state.data ?? {};
    function getView() {
        if(chatDate < threeDaysAgo) return <NotVisible/>
        switch (state.view) {
            case 'ADD_PARTICIPANT':
                return <AddParticipant participants={participants} category={category}/>;
            case 'CHAT_TEAM':
                return <ChatTeam date={tour.date} tourId={tour.tourId ?? tour.productId} productId={tour.productId} teamId={tour.team ?? tour.teamId} idx={0} live={true}
                                 searchKeyword={searchKeyword} participants={participants}/>;
            default:
                return <></>;
        }
    }

    return (
        <Drawer
            anchor={state.anchor}
            open={state.open}
            onClose={() =>
                dispatch({
                    type: 'CLOSE',
                })
            }
        >
            {getView()}
        </Drawer>
    );
};

function NotVisible(){
    return (
        <Box sx={{
            backgroundColor:'white',
            p:5,
        }}>
            <Typography>
                CANNOT DISPLAY THE PAST LIST
            </Typography>
        </Box>
    )
}
