import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Icon from '@mui/material/Icon';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';
import {pushVal} from '../../controller/firebase';


const METHODS = [
    {label: 'card', value: 'card', icon: 'credit_card'},
    {label: 'cash', value: 'cash', icon: 'money'},
];

const ACCOUNT_TYPES = {
    expenditure: {label: 'expenditure', value: 'expenditure', multiplier: -1},
    income: {label: 'income', value: 'income', multiplier: 1},
};

export default function PaymentPage({}) {

    const {guide} = useGuideContext();
    const {dashFormat} = useDateContext();
    const [method, setMethod] = useState(METHODS[0].value);
    const [amount, setAmount] = useState(0);
    const [accountType, setAccountType] = useState(Object.values(ACCOUNT_TYPES)[0].value);
    const [memo, setMemo] = useState('');

    const canChangeCurrency = guide?.admin;
    const baseCurrency = guide?.area?.toLowerCase().includes('tokyo') ? 'JPY' : 'KRW';
    const [currency, setCurrency] = useState(null);

    const changeValue = (v) => {
        const value = v
            ? Math.abs(v) * ACCOUNT_TYPES[accountType].multiplier
            : 0;
        setAmount(value);
    };

    const handleValue = (e) => {
        const value = e.target.value;
        changeValue(Number.isNaN(value) ? 0 : value);
    };

    const handleChangeMethod = (e, value) => {
        setMethod(e.target.value);
    };

    const handleChangeCurrency = (e, value) => {
        setCurrency(e.target.value);
    };

    const handleChangeAccountType = (e, value) => {
        setAccountType(value);

    };

    const handleMemo = (e) => {
        setMemo(e.target.value);
    };

    const clear = () => {
        setAmount(0);
        setMemo('');
        setAccountType(Object.values(ACCOUNT_TYPES)[0].value);
    };

    const handleConfirm = (e) => {
        const identifier = (guide?.email ?? '').split('@')[0];
        const date = dashFormat;
        const obj = {
            [method]: amount,
            category: 'Tour',
            currency: (currency ?? baseCurrency),
            area: guide?.area ?? 'Seoul',
            date,
            detail: memo,
            identifier: `${date}${identifier}`,
            title: 'GITA',
            writer: `(${guide?.name})${guide?.id}`,
            timestamp: Date.now(),
            createdAt: Date.now(),
            updatedAt: Date.now()
        };
        pushVal('account', obj).catch(console.error);
        clear();
    };

    useEffect(() => {
        changeValue(amount);
    }, [accountType]);

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                p: 2,
            }}
        >
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                >
                    <Select
                        onChange={handleChangeMethod}
                        value={method}
                        variant={'standard'}
                        sx={{
                            '&::before': {
                                display: 'none'
                            }
                        }}
                    >
                        {
                            METHODS.map((method) => (
                                <MenuItem
                                    key={method.value}
                                    value={method.value}
                                    variant={'standard'}
                                >
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Icon sx={{mr: 1}}>{method.icon}</Icon>
                                        {method.label.toUpperCase()}

                                    </Box>
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        canChangeCurrency
                            ?
                            <Select
                                disabled={!canChangeCurrency}
                                onChange={handleChangeCurrency}
                                value={currency ?? baseCurrency}
                                variant={'standard'}
                                sx={{
                                    '&::before': {
                                        display: 'none'
                                    }
                                }}
                            >
                                <MenuItem value={'KRW'}>
                                    ₩
                                </MenuItem>
                                <MenuItem value={'JPY'}>
                                    ¥
                                </MenuItem>
                            </Select>
                            : null
                    }
                </Box>
                <SpendIncome
                    value={accountType}
                    onChange={handleChangeAccountType}
                />
            </Box>
            <Box
                mt={1}
                mb={2}
            >
                <TextField
                    fullWidth
                    variant={'outlined'}
                    InputProps={
                        {
                            startAdornment: <InputAdornment position="start">{
                                (currency ?? baseCurrency) === 'JPY' ? '¥' : '₩'
                            }</InputAdornment>
                        }
                    }
                    type={'number'}
                    value={amount}
                    onChange={handleValue}
                />
            </Box>
            <Divider/>
            <Box mt={2}>
                <TextField
                    fullWidth
                    multiline
                    label={'MEMO'}
                    variant={'outlined'}
                    value={memo}
                    maxRows={4}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={handleMemo}
                />
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row-reverse'}
                mt={2}
            >
                <Button
                    color={'primary'}
                    onClick={handleConfirm}
                >
                    CONFIRM
                </Button>
                <Button
                    onClick={clear}
                    sx={{
                        color: 'gray'
                    }}
                >
                    CLEAR
                </Button>
            </Box>
        </Box>
    );
}

function SpendIncome({
                         value,
                         onChange,
                     }) {
    return (
        <FormControl component={'fieldset'}>
            <RadioGroup
                row
                value={value}
                onChange={onChange}>
                {
                    Object.values(ACCOUNT_TYPES).map((type) => (
                        <FormControlLabel
                            key={type.value}
                            value={type.value}
                            label={type.label.toUpperCase()}
                            control={<Radio/>}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
}
