import {useEffect, useState} from 'react';
import {listenWithChildValue} from '../index';

export default function useFListenWithChildVal(on, path, child, childVal, format = f => f, defaultValue) {
    const [data, setData] = useState(defaultValue ?? format(null));
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (on) {
            setIsLoading(true);
            const handler = (snapshot) => {
                setData(format(snapshot.val(), path, snapshot));
                setIsLoading(false);
            };
            const errorHandler = (e) => {
                setError(e);
            };
            const unsubscribe = listenWithChildValue(path, child, childVal, handler, errorHandler);
            return () => {
                unsubscribe();
            };
        }
    }, [on, path, child, childVal]);
    return {
        isLoading,
        data,
        error
    };
}
