import React, {useCallback, useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import QrIcon from '@mui/icons-material/QrCode';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyIcon from '@mui/icons-material/ContentCopy';


import copy from '../../utils/copy';

import {callFunction, transaction, updateFireStore} from '../../controller/firebase';
import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';
import Stack from "@mui/material/Stack";
import QRCode from "react-qr-code";

import html2canvas from 'html2canvas';
import sanitizeFileName from "../../utils/senitizeFileName";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {logAction} from "../../controller/firebase/logAction";
import Divider from "@mui/material/Divider";
import {readRealtime, removeRealtime, updateRealtime} from "../../controller/chat/firebase";
import dayjs from "dayjs";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";


export default function ReservationItem({
                                          products,
                                          productId,
                                          teamId,
                                          team,
                                          tour,
                                          date,
                                          reservation,
                                          live,
                                          isMyTeam,
                                          belongMyTour,
                                          onTimestamp
                                        }) {
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const {isToday, dashFormat} = useDateContext();
  const {guide, guideMap} = useGuideContext();
  // const [checked, setChecked] = useState(reservation.check);
  // const [noShow, setNoShow] = useState(reservation.noShow);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [qr, setQr] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const product = products?.[productId];
  const pickups = Object.values(product?.chat?.pickup ?? {}).filter(p => p.use).sort(({order: a = 99}, {order: b = 99}) => a > b ? 1 : -1);
  const isEditable = guide.level <= 1 && live;
  const [updates, setUpdates] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(!!(reservation.mailedAt ?? false));
  const isCheckable = live;
  const isControlable = live && (guide?.admin || belongMyTour );
  const isDeletable = live && (guide?.outsource) && guide.managingProducts?.includes(productId) && reservation.id.startsWith('KTOS');
  const noShow = reservation.noShow;

  const teams = Object.entries(tour?.teams ?? {})

      .map(([teamId, team], idx) => {
        team.id = teamId
        team.idx = (idx + 1)
        return team
      })
      .sort(({idx: a}, {idx: b}) => a - b)
  const isLastMinute = reservation.reservedAt && dayjs(reservation.reservedAt).diff(dayjs(dashFormat).set('hours', 0).set('minutes', 0).set('seconds', 0), 'hours') > -16;

  const productOptions = Object.values(products ?? {}).filter((p) => {
    return p.status === 'ON';
  })
      .map((product) => ({value: product.id, label: product.name}))
      .sort((a, b) => a.label > b.label ? 1 : -1);

  const handleReassignTeam = (destinationTeamId) => {
    if (!tour) return
    if (!tour.teams) return
    if (!product) return
    if (!destinationTeamId || !tour.teams[destinationTeamId]) return alert("대상 팀이 존재하지 않습니다.");
    (async () => {

      const team = tour.teams[teamId]
      const productName = product.name ?? productId
      const toTeamNumb = teams.findIndex((t) => t.id === destinationTeamId) + 1;
      const fromTeamNumb = teams.findIndex((t) => t.id === team.id) + 1;
      const movingReservationsSignatures = `${reservation.id}(${reservation.agency}, ${reservation.agencyCode})`
      const changes = `Team change(CS) ${date}/${productName} reservations:[TEAM${fromTeamNumb}(${team.id}) => TEAM${toTeamNumb}(${destinationTeamId})]\r\n${movingReservationsSignatures}`

      // if (team.reservations?.[id] && destinationTeamId !== teamId) {
      if (reservation.path && destinationTeamId && /operation\/.+\/tours\/.+\/teams\/.+\/reservations\/.+/.exec(reservation.path)) {
        const reservationVal = await readRealtime(reservation.path);
        const removePath = reservation.path;
        const replacedPath = reservation.path.replace(/\/teams\/(.+?)\//gi, `/teams/${destinationTeamId}/`);
        await updateRealtime(replacedPath, reservationVal);
        await removeRealtime(removePath);

        logAction(guide.id, 'OPERATION', replacedPath, "CHANGE RESERVATION TEAM", changes, {
          fromPath: removePath,
          toPath: replacedPath,
          tourId: tour?.id ?? product?.id ?? productId,
          teamId: team?.id ?? teamId,
          date,
          productName: product?.name ?? productName ?? productId,
          cleansedReservation: reservationVal,
        }, {reservation: reservationVal});
        return {
          destinationTeamId,
          destinationTeam: tour.teams[destinationTeamId],
          reservation: reservationVal,
          reservationPath: replacedPath
        };
      }
    })()
        .then((reassignResults) => {
          if (!reassignResults) {
            return;
          }
          const {destinationTeam, reservation, reservationPath} = reassignResults;
          if (window.confirm(`${reservation.clientName}(${reservation.email})로 이메일(no-reply@ktourstory.com)을 발송하시겠습니까?`)) {
            if (reservation.mailedAt && !window.confirm(`${new Date(reservation.mailedAt).toLocaleString()} 발송 내역이 있습니다. 다시 발송하시겠습니까?`)) {
              return
            }
            const emailAddress = 'no-reply@ktourstory.com';
            const isSeoul = product.area.toLowerCase() === 'seoul';
            const selectedGuides = destinationTeam.guides;
            const kup = isSeoul && selectedGuides.length > 0;

            setIsSending(true);
            callFunction('sendReservationMailWith', {emailAddress, reservationId: reservation.id, kup})
                .then((resp) => {
                  if (resp.success) {
                    alert(`메일 발송 성공(${reservation.email})`)
                    setIsSent(true);
                    transaction(`${reservationPath}`, (reservation) => {
                      if (reservation) {
                        reservation.mailedAt = new Date().toString();
                        reservation.kup = kup
                      }
                      return reservation;
                    }).catch(console.error)
                  } else {
                    throw new Error(resp.reason ?? "unknown")
                  }
                })
                .catch(() => {
                  alert(`메일 발송 실패(${reservation.email})`)
                })
                .finally(() => {
                  setIsSending(false)
                })
          }
        })
        .catch((e) => {
          console.error(e)
          alert("팀 변경 중 문제가 발생했습니다.")
        })

  }

  const handleUpdateReservation = (e) => {
    const property = e.target.name;
    const type = e.target.type;
    const value = type === 'number' ? Number(e.target.value) : e.target.value;
    setUpdates((prev) => {
      if (!prev) return {[property]: value};
      return ({...prev, [property]: value});
    })
  }

  const handleUpdateReservationProduct = (productId) => {
    const product = products?.[productId];
    if (!product) return;
    setUpdates((prev) => {
      if (!prev) return {productId: product.id, product: product.name};
      return ({...prev, productId: product.id, product: product.name});
    })
  }

  const handleUpdateReservationDate = (dayjs) => {
    const formatted = dayjs.format('YYYY-MM-DD');
    setUpdates((prev) => {
      if (!prev) return {date: formatted};
      return {...prev, date: formatted};
    })
  }

  const handleSaveUpdates = () => {
    const timestamp = Date.now();
    if (!guide) return;

    const cleanedUpdateTuples = Object.entries(updates ?? {}).filter(([_, value]) => value !== undefined);
    if (cleanedUpdateTuples.length === 0) return;

    const cleanedUpdates = Object.fromEntries(cleanedUpdateTuples);
    if (cleanedUpdates.productId && !window.confirm(`상품을 ${cleanedUpdates.product}로 변경합니다.`)) return;
    if (cleanedUpdates.date && !window.confirm(`날짜를 ${cleanedUpdates.date}로 변경합니다.`)) return;

    setIsUpdating(true);
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        Object.entries(updates ?? {}).forEach(([prop, value]) => {
          if (value !== undefined) {
            reservation[prop] = value;
          }
        })
      }
      return reservation;
    })
        .then(() => {
          // setChecked(value);
          onTimestamp(timestamp);
          return updateFireStore('reservation', reservation.id, cleanedUpdates);
        })
        .then(() => {
          const diff = Object.entries(reservation ?? {}).filter((entry) => JSON.stringify(entry[1]) !== JSON.stringify((reservation)?.[entry[0]])).map((entry) => `[${entry[0]}, ${JSON.stringify(entry[1])} => ${JSON.stringify((updates)?.[entry[0]])}]`).join('\r\n')
          const value = {from: JSON.parse(JSON.stringify(reservation)), to: JSON.parse(JSON.stringify(updates))};
          logAction(guide.id, 'RESERVATION', `${reservation.path}`, 'UPDATE RESERVATION',
              `Update reservation: ${reservation?.id}(${reservation?.date}, ${reservation?.agency}, ${reservation?.agencyCode})\r\n${diff}`,
              value,
              {reservation: JSON.parse(JSON.stringify(reservation))}
          );
          setUpdates({});
        })
        .catch(e => {
          alert('Fail Update Reservations');
        })
        .finally(() => {
          setIsUpdating(false)
        });

  }

  const handleCancel = () => {
    const timestamp = Date.now();
    const canceledAt = new Date().toString();
    setIsUpdating(true);
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.canceledAt = canceledAt;
      }
      return reservation;
    })
        .then(() => {
          // setChecked(value);
          onTimestamp(timestamp);
          return updateFireStore('reservation', reservation.id, {canceledAt});
        })
        .then(() => {

          const cancelation = `Cancel Reservation On CS(GUIDE WEB) ${reservation.agencyCode}(${reservation.agency}) ${reservation.clientName}`;
          logAction(guide.id, 'RESERVATION', reservation.path, 'CANCEL RESERVATION', cancelation, {
            reservationId: reservation.id,
            agencyCode: reservation.agencyCode,
            agency: reservation.agency,
            clientName:reservation.clientName,
            canceledAt
          }, {reservation: JSON.parse(JSON.stringify(reservation))});
          setUpdates({});
        })
        .catch(e => {
          alert('Fail Cancel Reservations');
        })
        .finally(() => {
          setIsUpdating(false)
        });
  }


  const handleLiveChecked = (e, value) => {
    // setChecked(value);
    // todo use security regulation
    const timestamp = Date.now();
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.check = value;
        reservation.timestamp = timestamp
      }
      return reservation;
    }).then(() => {
      // setChecked(value);
      onTimestamp(timestamp);
      return updateFireStore('reservation', reservation.id, {check: value});
    })
        .catch(e => {
          alert('Please turn on sync when you check reservation.');
        });


  }
  const handleClickMessenger = (e) => {
    copy(reservation.messenger);
    window?.prompt('Messenger copied!', reservation.messenger);
  }

  const handleClickQR = (e) => {
    setQr((qr) => !qr);
  }

  const handleToggleNoShow = (e) => {
    const newNoShow = !noShow;
    // setNoShow(newNoShow);

    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.noShow = newNoShow;
      }
      return reservation;
    })
        .then(() => {
          return updateFireStore('reservation', reservation.id, {noShow: newNoShow})
        })
        .catch(e => {
          console.log(e);
          alert('Please turn on sync when you check reservation.');
        });

  };

  const handleDelete = () => {
    setIsCanceling(true);
    callFunction('cancelOutsourcedReservation', {reservationId: reservation.id})
        .then((r) => {
          console.log(r);
          alert('예약이 취소되었습니다.');
        })
        .catch((e) => {
          alert('예약을 취소할 수 없습니다.')
        })
        .finally(() => {
          setIsCanceling(false);
        })
  }

  const handleToggleOpen = useCallback((e) => {
    setOpen((v) => !v);
  }, [reservation.path]);

  const handleToggleExpand = useCallback((e) => {
    setExpand((v) => !v);
  }, [reservation.path]);

  const handleCopyGen = useCallback((text) => (e) => {
    copy(text);
  }, [reservation.path]);

  const stopPropagation = useCallback((e,) => {
    e.stopPropagation();
  }, []);

  const downloadQR = () => {
    const qr = qrRef.current;
    if (!qr) return;

    html2canvas(qr).then((canvas) => {
      // PNG 파일 다운로드
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement('a');
      downloadLink.href = pngFile;
      downloadLink.download = sanitizeFileName(`${reservation.clientName.replace(/\([^)]*\)/g, "")}-${reservation.agencyCode}.png`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }


  const handleChatDirect = async (e) => {
    setLoadingChat(true);
    try {
      const category = "CLIENT-GUIDE";
      const cId = `${category}:${productId}:${date}:${teamId}:${reservation.id}`
      const title = `${date}:${productId.split("_").pop()}:${reservation.clientName}`
      //
      const chatParams = {
        category,
        participant: {
          id: reservation.id,
          name: reservation.clientName,
          nameEn: "Client",
          type: "client",
          agent: {
            device: '',
            browser: '',
          },
        },
        cId,
        title,
        tour: {
          team: teamId,
          date: date,
          productId: productId,
        },
        reservation: {
          id: reservation.id,
          name: reservation.clientName,
          agencyCode: reservation.agencyCode
        }
      }

      const chatMeta = await callFunction('recallComprehensiveTourChat', chatParams);
      navigate(`/chat/rooms/${chatMeta.id}`, {state: {chatMeta, reservation}});

    } catch (e) {
      console.log(e);
      alert('참석이 어렵습니다.');
    }
    setLoadingChat(false);
  }


  const handleSendMail = () => {
    if (window.confirm(`${reservation.clientName}(${reservation.email})로 이메일(no-reply@ktourstory.com)을 발송하시겠습니까?`)) {
      const emailAddress = 'no-reply@ktourstory.com';
      const isSeoul = product.area.toLowerCase() === 'seoul';
      const selectedGuides = team.guides;
      const kup = isSeoul && selectedGuides.length > 0;

      setIsSending(true);
      callFunction('sendReservationMailWith', {emailAddress, reservationId: reservation.id, kup})
          .then((resp) => {
            if (resp.success) {
              alert(`메일 발송 성공(${reservation.email})`)
              setIsSent(true);
              transaction(`${reservation.path}`, (reservation) => {
                if (reservation) {
                  reservation.mailedAt = new Date().toString();
                  reservation.kup = kup
                }
                return reservation;
              }).catch(console.error)
            } else {
              throw new Error(resp.reason ?? "unknown")
            }
          })
          .catch(() => {
            alert(`메일 발송 실패(${reservation.email})`)
          })
          .finally(() => {
            setIsSending(false)
          })
    }
  }


  return (
      <ListItem
          sx={theme => ({
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            borderBottom: `solid 1px ${theme.palette.divider}`
          })}
      >
        <ListItemButton
            sx={{
              py: 0.5,
              px: 2,
              backgroundColor: reservation.agency === 'KTOS' ? '#e7e7ff' : '#f9f9fd',
            }}
            disableRipple
            disableTouchRipple
            onClick={handleToggleOpen}
        >
          <ListItemIcon
              sx={{
                minWidth: 0
              }}
              onClick={stopPropagation}
          >
            <Checkbox
                edge="start"
                disabled={!isCheckable}
                defaultChecked={reservation.check}
                // checked={checked}

                onChange={handleLiveChecked}
                disableRipple
            />
          </ListItemIcon>
          <ListItemText
              sx={{
                marginLeft: 0.5
              }}
              primaryTypographyProps={
                {component: 'div'}
              }
              secondaryTypographyProps={
                {component: 'div'}
              }
              primary={
                <Box>
                  {
                      isLastMinute &&
                      <Typography sx={{mb: '8px'}} color={'error'} variant={'caption'}>
                        Last Minute
                      </Typography>
                  }
                  <Box display={'flex'}
                       justifyContent={'space-between'}
                       alignItems={'center'}
                       marginBottom={0.5}
                  >
                    <Typography
                        fontWeight={'500'}
                        variant={'subtitle1'}
                        color={noShow ? 'grey.400' : 'default'}
                    >
                      {reservation.clientName.replace(/\([^)]*\)/g, "")} ({reservation.people})
                    </Typography>
                    <Chip size={'small'}
                          avatar={<Avatar>{reservation.agency}</Avatar>}
                          label={
                            <Typography
                                sx={{maxWidth: '120px'}}
                                variant={'subtitle2'}
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                                color={noShow ? 'grey.400' : 'default'}
                            >
                              {reservation.agencyCode}
                            </Typography>
                          }
                          color={'primary'}
                          variant={'outlined'}
                          sx={{
                            flexDirection: 'row-reverse',
                            border: 'none',
                            color: 'inherit',
                            fontWeight: '500'
                          }}
                    />
                  </Box>
                </Box>
              }
              secondary={
                <Grid container spacing={0.5}>
                  <Grid item xs={6}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        fontWeight={'500'}
                        color={noShow ? 'grey.400' : 'default'}
                    >
                      {`Ad: ${reservation.adult} `}
                      {`Kd: ${reservation.kid} `}
                      {`Bb: ${reservation.infant} `}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        textAlign={'right'}
                        whiteSpace={'nowrap'}
                        color={noShow ? 'grey.400' : 'default'}
                    >
                      <Typography
                          component={'span'}
                          variant={'subtitle2'}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          mx={1}
                      >
                        {reservation.nationality}
                      </Typography>
                      <Box
                          component={'span'}
                          display={'inline-block'}
                          width={'18px'}
                          height={'18px'}
                      >
                        {reservation.nationalityFlag}
                      </Box>
                    </Typography>

                  </Grid>
                  {/*<Grid item xs={6}>*/}
                  {/*    <Typography*/}
                  {/*        component={'p'}*/}
                  {/*        variant={'caption'}*/}
                  {/*        textOverflow={'ellipsis'}*/}
                  {/*        overflow={'hidden'}*/}
                  {/*        whiteSpace={'nowrap'}*/}
                  {/*        color={noShow ? 'grey.400' : 'default'}*/}
                  {/*    >*/}
                  {/*        {reservation.tel} <br/>*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={6}>*/}
                  {/*    <Typography*/}
                  {/*        component={'p'}*/}
                  {/*        variant={'caption'}*/}
                  {/*        textAlign={'right'}*/}
                  {/*        textOverflow={'ellipsis'}*/}
                  {/*        overflow={'hidden'}*/}
                  {/*        whiteSpace={'nowrap'}*/}
                  {/*        color={noShow ? 'grey.400' : 'default'}*/}
                  {/*    >*/}
                  {/*        {reservation.email} <br/>*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*{*/}
                  {/*    reservation.messenger && reservation.messenger.length > 5*/}
                  {/*        ? (*/}
                  {/*            <Grid item xs={12}>*/}
                  {/*                <Typography*/}
                  {/*                    component={'p'}*/}
                  {/*                    variant={'caption'}*/}
                  {/*                    textOverflow={'ellipsis'}*/}
                  {/*                    overflow={'hidden'}*/}
                  {/*                    whiteSpace={'nowrap'}*/}
                  {/*                >*/}
                  {/*                    {reservation.messenger} <br/>*/}
                  {/*                </Typography>*/}
                  {/*            </Grid>*/}
                  {/*        )*/}
                  {/*        : null*/}
                  {/*}*/}
                  <Grid item xs={10}>
                    {
                      (reservation.option ?? []).map((option) => (
                          <Stack flexDirection={'row'} gap={1}>
                            <Typography
                                component={'p'}
                                variant={'subtitle2'}
                                color={'primary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                            >
                              {option.option}
                            </Typography>
                            <Typography
                                component={'p'}
                                variant={'subtitle2'}
                                color={'primary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textAlign={'end'}
                            >
                              {option.people}
                            </Typography>
                          </Stack>
                      ))
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                        flexDirection={'row-reverse'}
                        gap={1}
                    >

                      {
                        reservation.language.split(',').map((l) => l.trim()).map((language) => (

                                <Box
                                    backgroundColor={
                                      language === 'ENGLISH'
                                          ? '#002868'
                                          : language === 'CHINESE'
                                              ? '#FF4E20'
                                              : '#1ba242'
                                    }
                                    color={'white'}
                                    sx={{
                                      opacity: noShow ? 0.15 : 1,
                                      px: 0.5,
                                      borderRadius: 1
                                    }}
                                >
                                  <Typography
                                      component={'p'}
                                      variant={'subtitle2'}
                                      textOverflow={'ellipsis'}
                                      overflow={'hidden'}
                                      whiteSpace={'nowrap'}
                                      textAlign={'right'}
                                      color={'inherit'}
                                  >
                                    {
                                      language === 'ENGLISH'
                                          ? 'EN'
                                          : language === 'CHINESE'
                                              ?
                                              'CN'
                                              : language === 'JAPANESE'
                                                  ? 'JP'
                                                  : language === 'KOREAN'
                                                      ?
                                                      'KO'
                                                      : language

                                    }
                                  </Typography>
                                </Box>
                            )
                        )}

                      <Box>
                        <Typography
                            component={'p'}
                            variant={'subtitle2'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                            whiteSpace={'nowrap'}
                            textAlign={'right'}
                            color={noShow ? 'grey.400' : 'secondary.main'}
                        >
                          {reservation.stroller ? '유모차' : ''}
                        </Typography>
                      </Box>

                    </Stack>

                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        color={'secondary'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                    >
                      {reservation.memo}
                    </Typography>
                  </Grid>
                </Grid>
              }
          />
        </ListItemButton>
        <Collapse
            unmountOnExit
            in={open && guide.level <= 2 /*driver는 이용 불가*/}
            timeout={100}
        >
          <Box
              sx={{
                paddingTop: 0.5,
                paddingBottom: 1,
                px: 2,
                backgroundColor: '#f9f9fd',
                textAlign: 'center'
              }}
          >
            <Button
                fullWidth
                color={'primary'}
                startIcon={<QrIcon/>}
                variant={'outlined'}
                onClick={handleClickQR}
                sx={{
                  marginBottom: 1.5
                }}
            >
              TOUR CHAT (QR,LINK)
            </Button>
            {
              qr ?
                  <Box
                      sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center'}}
                  >
                    <Box
                        ref={qrRef}
                        sx={{
                          width: '100%',
                          display: 'inline-flex',
                          flexDirection: 'column',
                          gap: 2,
                          alignItems: 'center',
                          backgroundColor: 'white',
                          p: 3,
                        }}
                    >
                      <QRCode
                          value={
                            reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                          }/>
                      <Typography fontWeight={"bold"}>
                        {reservation.clientName.replace(/\([^)]*\)/g, "")}<br/>
                        {reservation.agencyCode}
                      </Typography>
                    </Box>
                    <Box
                        width={'100%'}
                        px={1}
                    >
                      <TextField fullWidth disabled variant={'standard'}
                                 value={
                                   reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                       `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                       : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                 }/>
                    </Box>
                    <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 2,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                    >
                      <Button variant={'text'} onClick={downloadQR}>
                        DOWNLOAD QR
                      </Button>
                      <Button onClick={() => {
                        copy(
                            reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`)
                      }}>
                        COPY LINK
                      </Button>
                    </Box>
                  </Box>
                  : null
            }

            {/*<Button*/}
            {/*    fullWidth*/}
            {/*    color={'primary'}*/}
            {/*    startIcon={loadingChat ? <CircularProgress size={24}/> : <ChatIcon/>}*/}
            {/*    variant={'outlined'}*/}
            {/*    onClick={handleChatDirect}*/}
            {/*    sx={{*/}
            {/*      marginBottom: 1.5*/}
            {/*    }}*/}
            {/*>*/}
            {/*  CHAT DIRECT*/}
            {/*</Button>*/}
            <Button
                disabled={(reservation.tel?.length ?? 0) < 5}
                fullWidth
                component={'a'}
                href={`tel:${reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}`}
                color={'primary'}
                startIcon={<PhoneIcon/>}
                variant={'outlined'}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}
            </Button>
            <Button
                fullWidth
                disabled={(reservation.email?.length ?? 0) < 5}
                component={'a'}
                href={`mailto:${reservation.email}?subject="Contact From Today's Tour"&body="Hello!"}`}
                color={'secondary'}
                startIcon={<EmailIcon/>}
                variant={'outlined'}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.email}
            </Button>
            <Button
                fullWidth
                disabled={(reservation.messenger?.length ?? 0) < 5}
                color={'secondary'}
                startIcon={<ChatIcon/>}
                variant={'outlined'}
                onClick={handleClickMessenger}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.messenger}
            </Button>
            <Button
                fullWidth
                disabled={!isControlable}
                color={'error'}
                variant={'outlined'}
                onClick={handleToggleNoShow}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {noShow ? 'Check In' : 'No Show'}
            </Button>
            {
              isDeletable
                  ? (
                      <Button
                          fullWidth
                          disabled={!isDeletable || isCanceling}
                          color={'error'}
                          variant={'outlined'}
                          onClick={handleDelete}
                          sx={{
                            marginBottom: 1.5
                          }}
                      >
                        {
                          isCanceling ? <CircularProgress size={24}/>
                              : 'Cancel(Delete)'
                        }
                      </Button>
                  )
                  : null
            }

            {
              !isEditable
                  ? (<Collapse
                      unmountOnExit
                      in={expand}
                      timeout={100}
                  >
                    <Box
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          backgroundColor: '#f9f9fd',
                          textAlign: 'left'
                        }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(`${reservation.clientName}(${reservation.people})`)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5,
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.clientName}({reservation.people})
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.agencyCode)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.agencyCode}({reservation.agency})
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.tel)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Box>
                              <Typography
                                  variant={'body2'}
                                  whiteSpace={'pre-wrap'}
                                  component={'span'}
                              >
                                {reservation.tel}
                              </Typography>
                            </Box>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.messenger)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.messenger}
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.email)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.email}
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        {
                          reservation.memo
                              ? (<Grid item xs={12}>
                                    <ButtonBase
                                        onClick={handleCopyGen(reservation.memo)}
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          py: 0.5
                                        }}
                                    >
                                      <Typography
                                          variant={'body2'}
                                          whiteSpace={'pre-wrap'}
                                          component={'span'}
                                      >
                                        {reservation.memo}
                                      </Typography>
                                      <CopyIcon
                                          sx={{
                                            width: '18px',
                                            height: '18px'
                                          }}
                                      />
                                    </ButtonBase>
                                  </Grid>
                              ) : null
                        }
                      </Grid>
                    </Box>
                  </Collapse>)
                  : (<Collapse
                      unmountOnExit
                      in={expand}
                      timeout={100}
                  >
                    <Box
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          backgroundColor: '#f9f9fd',
                          textAlign: 'left'
                        }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <MobileDatePicker
                              value={updates?.date ?? reservation.date}
                              onAccept={handleUpdateReservationDate}
                              onChange={handleUpdateReservationDate}
                              inputFormat={"YYYY/MM/DD"}
                              showToolbar={false}
                              renderInput={(props) => (
                                  <TextField
                                      fullWidth
                                      ref={props.inputRef}
                                      onClick={props.onClick}
                                      size={'small'}
                                      label={'Date'}
                                      name={'Date'}
                                      value={props.inputProps.value}
                                  />
                              )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              select
                              size={'small'}
                              label={'Product'}
                              name={'Product'}
                              SelectProps={{
                                native: true
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.productId ?? reservation.productId}
                              onChange={(e) => {
                                const productId = e.target.value;
                                handleUpdateReservationProduct(productId);
                              }}
                          >
                            {
                              productOptions.map((p) => (
                                  <option key={p.value} value={p.value}>{p.label}</option>))
                            }
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              select
                              size={'small'}
                              label={'Team'}
                              name={'team'}
                              SelectProps={{
                                native: true
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={teamId}
                              onChange={(e) => {
                                const teamId = e.target.value;
                                handleReassignTeam(teamId);
                              }}
                          >
                            {
                              teams.map((team, idx) => {
                                const guideNames = team.guides.map((gid) => guideMap.get(gid)?.name).join(',');
                                return (
                                    <option key={team.id}
                                            label={`TEAM ${idx + 1}${guideNames ? `(${guideNames})` : ''}`}
                                            value={team.id}/>
                                );
                              })
                            }
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                              fullWidth
                              disabled
                              size={'small'}
                              label={'Agency'}
                              name={'agency'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={reservation.agency}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                              fullWidth
                              disabled
                              size={'small'}
                              label={'AgencyCode'}
                              name={'agencyCode'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={reservation.agencyCode}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              select
                              size={'small'}
                              label={'Pickup Place'}
                              name={'pickupPlace'}
                              InputLabelProps={{shrink: true}}
                              SelectProps={{
                                native: true
                              }}
                              value={updates?.pickupPlace ?? reservation.pickupPlace}
                              onChange={handleUpdateReservation}
                          >
                            {
                              (pickups ?? []).map((p) => (
                                  <option key={p.place} value={p.place}>
                                    {p.place}
                                  </option>
                              ))
                            }
                          </TextField>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Name'}
                              type={'string'}
                              name={'clientName'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.clientName ?? reservation.clientName}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Adult'}
                              type={'number'}
                              name={'adult'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.adult ?? reservation.adult) || ''}
                              onChange={handleUpdateReservation} a
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Kid'}
                              type={'number'}
                              name={'kid'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.kid ?? reservation.kid) || ''}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Infant'}
                              type={'number'}
                              name={'infant'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.infant ?? reservation.infant) || ''}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Tel'}
                              type={'string'}
                              name={'tel'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.tel ?? reservation.tel}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Email'}
                              type={'string'}
                              name={'email'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.email ?? reservation.email}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Messenger'}
                              type={'string'}
                              name={'messenger'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.messenger ?? reservation.messenger}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>


                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              multiline
                              size={'small'}
                              label={'Memo'}
                              type={'string'}
                              name={'memo'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.memo ?? reservation.memo}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>


                        <Grid item xs={12}>
                          <Button
                              fullWidth
                              variant={'contained'}
                              color={'primary'}
                              disabled={isUpdating || Object.values(updates).filter(v => v !== undefined).length === 0}
                              onClick={handleSaveUpdates}>
                            Save Reservation
                            {
                                isUpdating &&
                                <CircularProgress size={'small'}/>
                            }
                          </Button>
                        </Grid>

                        <Grid item xs={isSent ? 8 : 12}>
                          <Button
                              fullWidth
                              variant={'contained'}
                              color={'secondary'}
                              disabled={isSending || isSent || !reservation.email}
                              onClick={handleSendMail}
                          >
                            Send Mail
                            {
                                isSending && !isSent
                                && <CircularProgress size={'small'}/>
                            }
                          </Button>
                        </Grid>
                        {
                            isSent && (
                                <Grid item xs={isSent ? 4 : 0}>
                                  <Button
                                      fullWidth
                                      variant={'outlined'}
                                      color={'secondary'}
                                      disabled={isSending || !isSent}
                                      onClick={handleSendMail}
                                  >
                                    Resend
                                    {
                                        isSending && isSent
                                        && <CircularProgress size={'small'}/>
                                    }
                                  </Button>
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                          <Button
                              fullWidth
                              variant={'contained'}
                              color={'error'}
                              disabled={isUpdating}
                              onClick={handleCancel}>
                            Cancel Reservation
                            {
                                isUpdating &&
                                <CircularProgress size={'small'}/>
                            }
                          </Button>
                        </Grid>


                      </Grid>
                    </Box>
                  </Collapse>)
            }

            <ButtonBase
                onClick={handleToggleExpand}
                sx={{
                  width: '100%',
                  py: 0,
                  transform: `rotate(${expand ? 180 : 360}deg)`
                }}
            >
              <ExpandMoreIcon/>
            </ButtonBase>
          </Box>
        </Collapse>


      </ListItem>
  );
}
