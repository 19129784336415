import {useEffect, createContext, useCallback, useContext, useMemo, useState, useLayoutEffect} from 'react';

import formatDash from '../../utils/formatDash';

export const DateContext = createContext({
  date: new Date(),
  setDate: () => {
  }
});

export function useDateContext() {
  return useContext(DateContext);
}

export function DateContextProvider({children}) {
  const [date, setDate] = useState(new Date());
  const handleDate = (datelike) => {
    setDate(datelike);
  };

  return (
      <DateContext.Provider value={{
        isToday: formatDash(date) === formatDash(new Date()),
        date,
        dashFormat: formatDash(date),
        setDate: handleDate,
      }}>
        {children}
      </DateContext.Provider>
  );
}
